<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" /> -->
<link rel="stylesheet" type="text/css" href="https://fonts.gastroguide.de/Material+Icons" />
<div class="custom-colors">
  <ng-template [ngIf]="!settings">
    <div class="loading-page">
      <div class="spinner">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="40"> </mat-progress-spinner>
      </div>
      <!-- <h3>{{ 'common.pleaseWait' | translate }}</h3> -->
    </div>
  </ng-template>

  <ng-template [ngIf]="settings">
    <div
      [class.content-wrap]="!iFrameMode"
      fxLayout="column"
      fxLayoutAlign="center start"
      [fxFlex.gt-xs]="iFrameMode ? 100 : 90"
      [fxFlex.gt-sm]="iFrameMode ? 100 : 80"
      [fxFlex.gt-md]="iFrameMode ? 100 : 60"
    >
      <div class="heading">
        <img
          src="//cdn.gastroguide.de/{{ client?.logo }}"
          *ngIf="!iFrameMode && client && client.logo"
          class="client-logo"
        />
        <h1 *ngIf="!iFrameMode" fxHide.xs>
          <ng-template>{{
            'widget.reservation.title' | translate: { clientName: client?.name | specialCharacters }
          }}</ng-template>
        </h1>
      </div>
      <div style="padding: 20px;" *ngIf="editPage && reservationRes">
        <div>
          <label> {{ 'widget.reservation.reservationDate' | translate }}:</label> &nbsp;
          {{ reservationRes?.reservedFor | date: 'EEEE, dd.MM.yyyy HH:mm' }}
        </div>
        <div>
          <label> {{ 'widget.reservation.peopleCount' | translate }}:</label> &nbsp; {{ reservationRes.peopleCount }}
        </div>
        <div *ngIf="reservationRes.shift && reservationRes.shift.name">
          <label> {{ 'widget.reservation.shiftName' | translate }}:</label>&nbsp;
          {{ reservationRes.shift.name | googleTranslate: userLang | async }}
        </div>
      </div>

      <div class="dropdown-item lang-btn" *ngIf="settings && settings.showTranslation === 1">
        <ng-select
          [clearable]="false"
          [searchable]="false"
          [(ngModel)]="userLang"
          (change)="changeLang($event)"
          #dropdown
        >
          <ng-option *ngFor="let val of languageArray" [value]="val.value">
            <img [src]="val.flag" alt="Flag" class="language-flag" />
            <span>{{ val.lang }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div [class.content]="!iFrameMode" [style.width]="widgetWidth ? widgetWidth : '100%'">
        <div class="error-msg" style="padding: 10px;" *ngIf="!settings.isActive">
          <span *ngIf="settings.reservationInactiveText; else defaultReservationInactiveText">
            {{ settings.reservationInactiveText | googleTranslate: userLang | async }}
          </span>
          <ng-template #defaultReservationInactiveText>
            <span> {{ 'widget.reservation.disabled' | translate }} </span>
          </ng-template>
        </div>
        <mat-horizontal-stepper
          linear="true"
          labelPosition="bottom"
          #stepper
          *ngIf="!showPayment && settings.isActive"
          (selectionChange)="showReservationPage()"
        >
          <ng-template matStepperIcon="edit">
            <svg-icon
              src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/edit2.svg"
              class="white-svg"
              [svgStyle]="{ 'height.px': 12 }"
            ></svg-icon>
          </ng-template>

          <ng-template matStepperIcon="done">
            <svg-icon
              src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/check-2.svg"
              class="white-svg"
              [svgStyle]="{ 'height.px': 12 }"
            ></svg-icon>
          </ng-template>
          <!-- Find Table Starts -->
          <mat-step [editable]="isEditable" [stepControl]="reservationFormGroup">
            <ng-template matStepLabel>{{ 'widget.reservation.steps.findTable' | translate }}</ng-template>

            <ng-template [ngIf]="showReservationData">
              <div
                class="inline-select"
                fxLayoutGap="15px grid"
                fxLayout="row wrap"
                fxLayout.xs="column wrap"
                *ngIf="!preSelectedShift; else preSelectedShiftTemplate"
              >
                <ng-container *ngIf="showShiftFirstTemplate; else dateFirstTemplate">
                  <ng-container *ngTemplateOutlet="shiftTemplate"></ng-container>
                  <ng-container *ngTemplateOutlet="dateTemplateDesktop"></ng-container>
                </ng-container>

                <ng-template #dateFirstTemplate>
                  <ng-container *ngTemplateOutlet="dateTemplateDesktop"></ng-container>
                  <ng-container *ngTemplateOutlet="shiftTemplate"></ng-container>
                </ng-template>

                <ng-container *ngIf="selectedShift">
                  <ng-container *ngTemplateOutlet="guestCountTemplate"></ng-container>
                </ng-container>
              </div>

              <!-- Guest count div -->
              <ng-template #guestCountTemplate>
                <div fxFlex>
                  <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    (open)="onOpen()"
                    class="select-item select-shift"
                    [(ngModel)]="guestCount"
                    (change)="changeGuestCount()"
                    [placeholder]="
                      selectedShiftObject?.type === 0
                        ? 'Personenanzahl wählen'
                        : selectedShiftObject?.type === 1
                        ? 'Anzahl Tickets wählen'
                        : selectedShiftObject?.type === 2
                        ? 'Anzahl ' + selectedShiftObject?.tableLabel?.amountLabelPlural + ' wählen'
                        : ''
                    "
                    #dropdown
                  >
                    <ng-option *ngFor="let val of guestCountSelect" [value]="val">
                      <ng-container *ngIf="selectedShiftObject?.type === 0">
                        {{ val }} {{ 'widget.reservation.guestCount' | translate }}
                      </ng-container>
                      <ng-container *ngIf="selectedShiftObject?.type === 1">
                        {{ val }}
                        {{
                          (val === 1
                            ? 'widget.reservation.ticketCountSingular'
                            : 'widget.reservation.ticketCountPlural'
                          ) | translate
                        }}
                      </ng-container>
                      <ng-container *ngIf="selectedShiftObject?.type === 2">
                        {{ val }}
                        {{
                          val === 1
                            ? selectedShiftObject.tableLabel?.amountLabelSingle
                            : selectedShiftObject.tableLabel?.amountLabelPlural
                        }}
                      </ng-container>
                    </ng-option>
                  </ng-select>
                </div>
              </ng-template>

              <!-- Shift selection div -->
              <ng-template #shiftTemplate>
                <div fxFlex>
                  <ng-select
                    [items]="shifts"
                    bindValue="id"
                    [ngClass]="{ 'show-shift-first': showShiftFirstTemplate }"
                    class="select-item select-shift"
                    [disabled]="!shifts || !shifts.length"
                    [(ngModel)]="selectedShift"
                    [placeholder]="'widget.reservation.selectTime' | translate"
                    (change)="changeShiftValue()"
                    [clearable]="false"
                    [searchable]="false"
                    (open)="onOpen()"
                    [loading]="selectedDate && !shifts"
                    #dropdown
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.name | googleTranslate: userLang | async }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{ item.name | googleTranslate: userLang | async }}<br />
                      <span *ngIf="item.startTime" class="badge badge-default"
                        >{{ item.startTime }} - {{ item.endTime }} Uhr</span
                      >
                    </ng-template>
                  </ng-select>
                </div>
              </ng-template>

              <!-- Date selection desktop div -->
              <ng-template #dateTemplateDesktop>
                <div class="select-item select-date" fxFlex>
                  <input
                    class="form-control"
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="dateFilter"
                    matInput
                    [min]="todayDate"
                    [max]="reservationUntil"
                    [matDatepicker]="picker"
                    (focus)="picker.open()"
                    (dateChange)="changeDate()"
                    [(ngModel)]="selectedDate"
                    placeholder="{{ 'widget.reservation.selectDate' | translate }}"
                    autocomplete="false"
                    readonly
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <svg-icon
                      src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/arrow-down.svg"
                      [svgStyle]="{ 'height.px': 24 }"
                      matDatepickerToggleIcon
                    ></svg-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <div class="openinghours-today" *ngIf="openingHoursToday">
                    {{ 'widget.reservation.openingHours' | translate }}:
                    <span *ngFor="let oh of openingHoursToday">
                      <ng-template [ngIf]="!oh.tTo || !oh.tTo.length">ab</ng-template> {{ oh.tFrom }}
                      <ng-template [ngIf]="oh.tTo && oh.tTo.length">- {{ oh.tTo }}</ng-template>
                      <ng-template [ngIf]="oh.firstOpeningPeriod === 'yes' && openingHoursToday.length > 1">
                        &
                      </ng-template>
                    </span>
                    Uhr
                  </div>
                </div>
              </ng-template>

              <!-- Date selection mobile div -->
              <ng-template #dateTemplateMobile>
                <div class="select-item select-date" fxFlex>
                  <mat-calendar
                    *ngIf="datesToHighlight && !preSelectedDate"
                    [dateFilter]="dateFilter"
                    [(selected)]="selectedDate"
                    [minDate]="todayDate"
                    [maxDate]="reservationUntil"
                    (selectedChange)="changeDate()"
                    (selectedDate)="(selectedDate)"
                    class="inline-calendar-card"
                  ></mat-calendar>
                </div>
              </ng-template>

              <ng-template #preSelectedShiftTemplate>
                <h3 *ngIf="preSelectedShiftObject && preSelectedShiftObject.name && !preSelectedDate">
                  {{ 'widget.reservation.chooseGroupSizeDate' | translate }}
                  {{ preSelectedShiftObject.name | googleTranslate: userLang | async }}:
                </h3>
                <h3 *ngIf="preSelectedShiftObject && preSelectedShiftObject.name && preSelectedDate">
                  {{ 'widget.reservation.chooseGroupSize' | translate }}
                  {{ preSelectedShiftObject.name | googleTranslate: userLang | async }}:
                </h3>
                <br />

                <ng-template #pleaseWaitTemplate>
                  <div *ngIf="selectedDate && !selectedShiftObject && !errorMsg" style="text-align: center;">
                    <h3>{{ 'common.pleaseWait' | translate }}</h3>
                  </div>
                </ng-template>

                <div *ngIf="mobileWindow">
                  <div
                    class="inline-select no-flex"
                    fxLayoutGap="15px grid"
                    fxLayout="row wrap"
                    fxLayout.xs="column wrap"
                    style="display: block !important;"
                  >
                    <ng-container *ngTemplateOutlet="dateTemplateMobile"></ng-container>
                    <ng-container *ngTemplateOutlet="guestCountTemplate"></ng-container>
                    <ng-container *ngTemplateOutlet="pleaseWaitTemplate"></ng-container>
                  </div>
                </div>

                <div *ngIf="!mobileWindow">
                  <div class="inline-select" fxLayoutGap="15px grid" fxLayout="row wrap" fxLayout.xs="column wrap">
                    <ng-container *ngTemplateOutlet="guestCountTemplate"></ng-container>
                    <ng-template [ngIf]="datesToHighlight && !preSelectedDate">
                      <ng-container *ngTemplateOutlet="dateTemplateDesktop"></ng-container>
                    </ng-template>
                  </div>
                  <ng-container *ngTemplateOutlet="pleaseWaitTemplate"></ng-container>
                </div>
              </ng-template>

              <ng-template [ngIf]="alerts.length">
                <div *ngFor="let alert of alerts" class="alert alert-warning">
                  {{ alert.info | googleTranslate: userLang | async }}
                  <a
                    *ngIf="alert.fileUrl"
                    href="https://cdn.gastroguide.de/{{ alert.fileUrl }}"
                    class="pdf-files file-url"
                    >( <i class="fas fa-file-download"></i>
                    {{ 'widget.reservation.extraInfos.InformationDownload' | translate }})
                  </a>
                </div>
              </ng-template>

              <div
                class="alert alert-info"
                *ngIf="selectedShiftObject && selectedShiftObject.info && selectedShiftObject.info.length"
              >
                {{ selectedShiftObject.info | googleTranslate: userLang | async }}
              </div>

              <div
                class="alert alert-warning"
                *ngIf="
                  selectedShiftObject &&
                  selectedShiftObject.payment &&
                  guestCount >= selectedShiftObject.payment.minGuests &&
                  settings.paymentSystem
                "
              >
                <b>{{ 'widget.reservation.extraInfos.Danger' | translate }}:</b>
                {{ selectedShiftObject.payment.infoText | googleTranslate: userLang | async }}
              </div>

              <div *ngIf="errorMsg" class="error-msg">
                <i class="fas fa-exclamation-triangle"></i> {{ errorMsg | googleTranslate: userLang | async }}
              </div>

              <div *ngIf="isLoading" style="height: 40px">
                <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate"> </mat-progress-spinner>
              </div>
              <ng-template [ngIf]="!errorMsg && !isLoading">
                <!-- Ticket type Tickets -->
                <div *ngIf="selectedShiftObject && (selectedShiftObject.type === 1 || selectedShiftObject.type === 2)">
                  <div class="option-row available-times" *ngIf="ticketData && ticketData.length > 0">
                    <div *ngIf="ticketData && ticketData.length">
                      <h3>{{ 'widget.reservation.ticketsAvailable' | translate }}</h3>
                      <button
                        mat-flat-button
                        color="primary"
                        *ngFor="let ticket of ticketData"
                        (click)="selectTicket(ticket)"
                        [class.selected]="selectedTicket && selectedTicket.name === ticket.name"
                      >
                        <span [innerHTML]="ticket.name | googleTranslate: userLang | async"></span>
                      </button>
                      <div *ngIf="selectedTicket">
                        <h3>
                          {{
                            'widget.reservation.selectTicketOption'
                              | translate
                                : {
                                    labelName:
                                      selectedShiftObject?.type === 0
                                        ? ('widget.reservation.guestCount' | translate)
                                        : selectedShiftObject?.type === 1
                                        ? ('widget.reservation.ticketCountSingular' | translate)
                                        : selectedShiftObject?.tableLabel?.amountLabelSingle || 'ticket'
                                  }
                          }}
                        </h3>
                        <!-- <div class="category-buttons" *ngFor="let ticket of ticketData"> -->
                        <div class="category-buttons">
                          <button
                            mat-flat-button
                            color="primary"
                            *ngFor="let category of selectedTicket.category"
                            (click)="selectTicketCategory(0, category, selectedTicket)"
                            style="text-align: left;"
                            [class.selected]="selectedTicketData[0] && selectedTicketData[0].name === category.name"
                          >
                            <span [innerHTML]="category.name | googleTranslate: userLang | async"></span> ({{
                              category.price | number: '1.2'
                            }}
                            €)
                            <br />
                            <span>{{ category.description }}</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="option-row available-times"
                      *ngIf="selectedTicket && selectedTicket.option && selectedTicket.option.length > 0"
                    >
                      <h3>{{ 'widget.reservation.ticketAddOns' | translate }}</h3>
                      <div class="ticket-addon">
                        <ng-container *ngFor="let option of selectedTicket.option; let i = index">
                          <h3 [innerHTML]="option.name | googleTranslate: userLang | async"></h3>
                          <div>
                            <number-spinner
                              class="ticketadd-on-spinner"
                              name="ticket-addons"
                              [min]="0"
                              [max]="1000"
                              [step]="1"
                              [precision]="1"
                              [inputDisabled]="false"
                              [defaultValue]="
                                selectedTicketAddOn[i] && selectedTicketAddOn[i].count
                                  ? selectedTicketAddOn[i].count
                                  : 0
                              "
                              (whenChange)="selectTicketAddOns(i, $event, option)"
                            ></number-spinner>

                            <span
                              style="margin:0;margin-left:1%;padding:0.25rem;"
                              class="alert alert-warning borderless"
                              *ngIf="selectedTicketAddOn[i] && selectedTicketAddOn[i].optionAmount"
                            >
                              {{ 'widget.reservation.price' | translate }}:
                              {{
                                selectedTicketAddOn[i].optionAmount
                                  ? (selectedTicketAddOn[i].optionAmount | number: '1.2')
                                  : 0
                              }}
                              €
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Ticket type -->

                <ng-container
                  *ngIf="
                    selectedShiftObject?.type === 0 ||
                    selectedTicketData.length ||
                    (selectedShiftObject?.type === 2 && !selectedTicketData.length)
                  "
                >
                  <!-- Stay times -->
                  <div *ngIf="availableStaytime" class="option-row">
                    <div *ngIf="!selectedShiftFollowUpShift; else individualShiftTitle">
                      <h3>{{ 'widget.reservation.chooseStaytimeTitle' | translate }}</h3>
                    </div>
                    <ng-template #individualShiftTitle>
                      <h3>
                        {{
                          'widget.reservation.staytimeTitleIndividualShift'
                            | translate: { shiftName: selectedShiftObject.name }
                        }}
                      </h3>
                    </ng-template>

                    <div class="custom-slider">
                      <ng5-slider
                        [(value)]="selectedStaytime"
                        [options]="stayTimeOptions"
                        (userChange)="changeStaytime()"
                      >
                      </ng5-slider>
                    </div>
                  </div>
                  <!-- /Stay times -->

                  <!-- Rooms -->
                  <div
                    *ngIf="availableRooms && settings.roomChoice && availableRooms.length > 1"
                    class="option-row available-times"
                  >
                    <h3 *ngIf="!settings.roomChoiceMandatory">
                      {{ 'widget.reservation.chooseRoomTitle' | translate }}
                    </h3>
                    <h3 *ngIf="settings.roomChoiceMandatory">
                      {{ 'widget.reservation.chooseRoomMandatoryTitle' | translate }}
                    </h3>
                    <span *ngFor="let room of availableRooms" class="tooltip">
                      <span class="tooltiptext" *ngIf="getRoomTooltip(room)">{{ getRoomTooltip(room) }}</span>
                      <button
                        mat-flat-button
                        color="primary"
                        (click)="selectRoom(room)"
                        [class.selected]="selectedRoom === room.id"
                        [disabled]="room.maxGuests < guestCount || room.minGuests > guestCount"
                      >
                        <span [innerHTML]="room.name | googleTranslate: userLang | async"></span>
                      </button>
                    </span>

                    <div class="room-info alert alert-warning borderless" *ngIf="roomAlert">
                      <h5 class="alert-heading">{{ 'widget.reservation.roomAlert' | translate }}</h5>
                      <span [innerHTML]="roomAlert | googleTranslate: userLang | async"></span>
                    </div>
                  </div>
                  <!-- /Rooms -->

                  <!-- Times -->
                  <div *ngIf="availableTimes" class="option-row available-times">
                    <h3>
                      {{ 'widget.reservation.chooseTimeTitle' | translate }}
                      <span *ngIf="mainSlotError"> {{ 'widget.reservation.greyedTimeMsg' | translate }}</span
                      >:
                    </h3>
                    <div *ngIf="loadingFreeTables" style="height: 40px">
                      <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate">
                      </mat-progress-spinner>
                    </div>
                    <ng-template [ngIf]="!loadingFreeTables">
                      <button
                        mat-flat-button
                        color="primary"
                        *ngFor="let time of availableTimes"
                        (click)="selectTime(time)"
                        [class.selected]="selectedTime === time"
                        [disabled]="!isTimeAvailable(time)"
                        class="time-btn"
                      >
                        {{ time }}
                      </button>
                    </ng-template>
                  </div>
                  <!-- /Times -->

                  <div *ngIf="alternativeTimes && false" class="option-row">
                    <div class="error-msg no-times" *ngIf="alternativeTimes.length">
                      <i class="fas fa-frown"></i>
                      <span [innerHTML]="'widget.reservation.alternativeTimes' | translate"> </span>
                    </div>

                    <div class="error-msg no-times" *ngIf="!alternativeTimes.length">
                      <i class="fas fa-frown"></i>
                      <span>
                        {{ 'widget.reservation.noSpace' | translate }}
                      </span>
                    </div>

                    <div class="available-times" style="margin-top: 25px;">
                      <button
                        mat-flat-button
                        color="primary"
                        *ngFor="let time of alternativeTimes"
                        (click)="selectAlternativeTime(time)"
                        [class.selected]="selectedTime === time"
                        [disabled]="loadingFreeTables"
                        class="time-btn"
                      >
                        {{ time }}
                      </button>
                    </div>
                  </div>

                  <!-- Tickets -->
                  <ng-container *ngIf="selectedShiftObject?.type === 0">
                    <div class="option-row available-times" *ngIf="ticketData && ticketData.length > 0 && selectedTime">
                      <h3>{{ 'widget.reservation.ticketsAvailable' | translate }}</h3>

                      <button
                        mat-flat-button
                        color="primary"
                        *ngFor="let ticket of ticketData"
                        (click)="selectTicket(ticket)"
                        [class.selected]="selectedTicket && selectedTicket.name === ticket.name"
                      >
                        <span [innerHTML]="ticket.name | googleTranslate: userLang | async"></span>
                      </button>
                      <div *ngIf="selectedTicket">
                        <ng-container *ngFor="let guest of guestCountArray; let i = index">
                          <h3>{{ 'widget.reservation.guest' | translate }} : {{ i + 1 }}</h3>
                          <div class="category-buttons">
                            <button
                              mat-flat-button
                              color="primary"
                              *ngFor="let category of selectedTicket.category"
                              (click)="selectTicketCategory(i, category, selectedTicket)"
                              [class.selected]="selectedTicketData[i] && selectedTicketData[i].name === category.name"
                            >
                              <span [innerHTML]="category.name | googleTranslate: userLang | async"></span>
                            </button>
                            <input
                              placeholder="{{ 'widget.reservation.ticketHolder' | translate }}"
                              class="form-control-ticket-holder"
                              type="text"
                              [(ngModel)]="ticketHolder[i]"
                              *ngIf="
                                selectedTicketData[i] &&
                                selectedTicketData[i].name &&
                                selectedTicket.isTcktHldrNameCmplsr
                              "
                            />
                            <!-- <div style="margin-bottom:10px;"> -->
                            <span
                              style="margin:0;padding:0.25rem;"
                              class="alert alert-warning borderless price-info"
                              *ngIf="selectedTicketData[i] && selectedTicketData[i].name"
                            >
                              <!-- {{ selectedTicketData[i].description ? selectedTicketData[i].description : '' }} -->
                              <!-- <span *ngIf="selectedTicketData[i].description && selectedTicketData[i].price "> - </span> -->
                              <span *ngIf="selectedTicketData[i].price">
                                {{ 'widget.reservation.price' | translate }}:
                                {{ selectedTicketData[i].price ? (selectedTicketData[i].price | number: '1.2') : 0 }} €
                              </span>
                              <span *ngIf="!selectedTicketData[i].price">
                                {{ 'widget.reservation.forFree' | translate }}
                              </span>
                            </span>
                            <!-- </div> -->
                          </div>
                        </ng-container>
                      </div>

                      <div
                        class="option-row available-times"
                        *ngIf="selectedTicket && selectedTicket.option && selectedTicket.option.length > 0"
                      >
                        <h3>{{ 'widget.reservation.ticketAddOns' | translate }}</h3>
                        <div class="ticket-addon">
                          <ng-container *ngFor="let option of selectedTicket.option; let i = index">
                            <h3 [innerHTML]="option.name | googleTranslate: userLang | async"></h3>
                            <div>
                              <number-spinner
                                class="ticketadd-on-spinner"
                                name="ticket-addons"
                                [min]="0"
                                [max]="1000"
                                [step]="1"
                                [precision]="1"
                                [inputDisabled]="false"
                                [defaultValue]="
                                  selectedTicketAddOn[i] && selectedTicketAddOn[i].count
                                    ? selectedTicketAddOn[i].count
                                    : 0
                                "
                                (whenChange)="selectTicketAddOns(i, $event, option)"
                              ></number-spinner>

                              <span
                                style="margin:0;margin-left:1%;padding:0.25rem;"
                                class="alert alert-warning borderless"
                                *ngIf="selectedTicketAddOn[i] && selectedTicketAddOn[i].optionAmount"
                              >
                                {{ 'widget.reservation.price' | translate }}:
                                {{
                                  selectedTicketAddOn[i].optionAmount
                                    ? (selectedTicketAddOn[i].optionAmount | number: '1.2')
                                    : 0
                                }}
                                €
                              </span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Questions -->
                  <div *ngIf="selectedTime && moveForward">
                    <div *ngFor="let question of questions; let i = index" class="option-row available-times">
                      <h3>
                        <span [innerHTML]="question.question | googleTranslate: userLang | async"></span>
                        <!-- {{ question.question | googleTranslate: userLang | async
                      }} -->
                        <ng-template [ngIf]="question.required % 2 != 0 ? true : false">*</ng-template>
                        <span class="form-desc" style="display:inline" *ngIf="question.remainingAmount >= 0">
                          ({{ 'widget.reservation.QuantityAvailable' | translate }}:
                          {{ question.remainingAmount }})</span
                        >
                      </h3>
                      <form [formGroup]="questionsForm">
                        <div *ngFor="let position of question?.addForm; let j = index">
                          <div class="row" [formGroup]="position">
                            <h6>
                              <ng-template [ngIf]="question.required > 1"
                                >{{ 'widget.reservation.guest' | translate }} : {{ j + 1 }}
                              </ng-template>
                            </h6>
                            <ng-template [ngIf]="question.answers && question.answers.length > 0">
                              <span *ngFor="let answer of question.answers">
                                <!-- <button
                                mat-flat-button
                                color="primary"
                                (click)="answerQuestion(question, answer, i, j)"
                                [class.selected]="
                                  questionAnswer[i] && questionAnswer[i][j] && questionAnswer[i][j]?.answer === answer
                                "
                              >
                                {{ answer.answer }}
                              </button> -->
                                <button
                                  mat-flat-button
                                  color="primary"
                                  (click)="answerQuestion(question, answer, i, j, isSelected(answer, i, j))"
                                  [class.selected]="isSelected(answer, i, j)"
                                >
                                  <span [innerHTML]="answer.answer | googleTranslate: userLang | async"></span>
                                </button>
                              </span>
                            </ng-template>
                            <ng-template
                              [ngIf]="!question.answers || (question.answers && question.answers.length == 0)"
                            >
                              <textarea
                                class="form-control"
                                placeholder=""
                                type="text"
                                name="freeText"
                                rows="3"
                                (change)="answerQuestion(question, $event.target.value, i, j)"
                              ></textarea>
                            </ng-template>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- /Questions -->
                </ng-container>
                <div *ngIf="errorMsgBottom" class="error-msg">
                  <i class="fas fa-exclamation-triangle"></i> {{ errorMsgBottom | googleTranslate: userLang | async }}
                </div>

                <div *ngIf="editErrorMsg" class="error-msg">
                  <i class="fas fa-exclamation-triangle"></i> {{ editErrorMsg | googleTranslate: userLang | async }}
                </div>

                <div *ngIf="checkSlotMsg" class="error-msg">
                  <i class="fas fa-exclamation-triangle"></i> {{ checkSlotMsg | googleTranslate: userLang | async }}
                </div>

                <!-- Follow Up Reservation Shift Start-->
                <div *ngIf="errorMsgFollowUpShift" class="error-msg">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{ errorMsgFollowUpShift | googleTranslate: userLang | async }}
                </div>

                <ng-template [ngIf]="!errorMsgFollowUpShift">
                  <!-- Stay times -->
                  <div *ngIf="availableStaytimeFollowUpShift" class="option-row">
                    <div *ngIf="!selectedShiftFollowUpShift; else individualShiftTitle">
                      <h3>{{ 'widget.reservation.chooseStaytimeTitle' | translate }}</h3>
                    </div>
                    <ng-template #individualShiftTitle>
                      <h3>
                        {{
                          'widget.reservation.staytimeTitleIndividualShift'
                            | translate: { shiftName: selectedShiftObjectFollowUpShift.name }
                        }}
                      </h3>
                    </ng-template>

                    <div class="custom-slider">
                      <ng5-slider
                        [(value)]="selectedStaytimeFollowUpShift"
                        [options]="stayTimeOptionsFollowUpShift"
                        (userChange)="changeStaytimeFollowUpShift()"
                      >
                      </ng5-slider>
                    </div>
                  </div>
                  <!-- /Stay times -->

                  <!-- Rooms -->
                  <div
                    *ngIf="availableRoomsFollowUpShift && settings.roomChoice && availableRoomsFollowUpShift.length > 1"
                    class="option-row available-times"
                  >
                    <h3 *ngIf="!settings.roomChoiceMandatory">
                      {{ 'widget.reservation.chooseRoomTitle' | translate }}
                    </h3>
                    <h3 *ngIf="settings.roomChoiceMandatory">
                      {{ 'widget.reservation.chooseRoomMandatoryTitle' | translate }}
                    </h3>
                    <span *ngFor="let room of availableRoomsFollowUpShift" class="tooltip">
                      <span class="tooltiptext" *ngIf="getRoomTooltip(room)">{{ getRoomTooltip(room) }}</span>
                      <button
                        mat-flat-button
                        color="primary"
                        (click)="selectRoomFollowUpShift(room)"
                        [class.selected]="selectedRoomFollowUpShift === room.id"
                        [disabled]="room.maxGuests < guestCount || room.minGuests > guestCount"
                      >
                        <span [innerHTML]="room.name | googleTranslate: userLang | async"></span>
                      </button>
                    </span>

                    <div class="room-info alert alert-warning borderless" *ngIf="roomAlertFollowUpShift">
                      <h5 class="alert-heading">{{ 'widget.reservation.roomAlert' | translate }}</h5>
                      <span [innerHTML]="roomAlertFollowUpShift | googleTranslate: userLang | async"></span>
                    </div>
                  </div>
                  <!-- /Rooms -->

                  <!-- Times -->
                  <ng-template
                    [ngIf]="
                      !settings.roomChoiceMandatory || (settings.roomChoiceMandatory && selectedRoomFollowUpShift)
                    "
                  >
                    <div *ngIf="availableTimesFollowUpShift" class="option-row available-times">
                      <h3>
                        {{ 'widget.reservation.chooseTimeTitle' | translate }}
                        <span *ngIf="followUpSlotError"> {{ 'widget.reservation.greyedTimeMsg' | translate }}</span
                        >:
                      </h3>
                      <div *ngIf="loadingFreeTablesFollowUpShift" style="height: 40px">
                        <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                      </div>
                      <ng-template [ngIf]="!loadingFreeTablesFollowUpShift">
                        <button
                          mat-flat-button
                          color="primary"
                          *ngFor="let time of availableTimesFollowUpShift"
                          (click)="selectTimeFollowUpShift(time)"
                          [class.selected]="selectedTimeFollowUpShift === time"
                          [disabled]="!isTimeAvailableFollowUpShift(time)"
                          [ngClass]="{
                            'follow-up-class': !(time >= firstSlotFollowUpShift && time <= lastSlotFollowUpShift)
                          }"
                          class="time-btn"
                        >
                          {{ time }}
                        </button>
                      </ng-template>
                    </div>
                  </ng-template>
                  <!-- /Times -->

                  <div *ngIf="errorMsgBottomFollowUpShift" class="error-msg">
                    <i class="fas fa-exclamation-triangle"></i>
                    {{ errorMsgBottomFollowUpShift | googleTranslate: userLang | async }}
                  </div>

                  <div *ngIf="checkSlotMsgFollowUpShift" class="error-msg">
                    <i class="fas fa-exclamation-triangle"></i>
                    {{ checkSlotMsgFollowUpShift | googleTranslate: userLang | async }}
                  </div>
                </ng-template>

                <!-- Follow Up Reservation Shift End-->
                <div
                  class="ta-activity"
                  *ngIf="!(loadingFreeTables || !selectedTime || !selectedTimeFollowUpShift || editErrorMsg !== '')"
                  [innerHTML]="showSummaryNote"
                ></div>

                <div class="alert alert-warning" *ngIf="selectedTimeFollowUpShift && shiftOverlapErrorMsg">
                  {{ 'widget.reservation.ShiftOverlapErrorMsg' | translate }}
                </div>

                <div
                  *ngIf="!moveForward && selectedTicketData && selectedTicketData.length > 0 && !selectedTicketPrice"
                  class="error-msg"
                >
                  <i class="fas fa-exclamation-triangle"></i> {{ 'widget.reservation.ticketAmountNotZero' | translate }}
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="btn-submit"
                  *ngIf="availableTimes && alternativeTimes.length"
                  [disabled]="
                    loadingFreeTables || !selectedTime || editErrorMsg !== '' || disableNextButton || !moveForward
                  "
                  (click)="checkForFreeTables(true)"
                >
                  {{ 'widget.reservation.checkTablesButton' | translate }}
                </button>

                <div class="loading-free-tables" *ngIf="false">
                  <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate"> </mat-progress-spinner>
                  {{ 'widget.reservation.searchingTables' | translate }}
                </div>
              </ng-template>
            </ng-template>

            <div class="addon-option" *ngFor="let addon of reservationAddOnTemplates; let i = index">
              <ng-template [ngIf]="addon.show">
                <span class="title">{{ addon.title | googleTranslate: userLang | async }}</span>
                <span class="title" style="float:right">{{ addon.price | number: '1.2' }} €</span>
                <br />
                <br />
                <span> {{ addon.description | googleTranslate: userLang | async }}</span>
                <br />
                <br />
                <img *ngIf="addon.image" class="addon-image" src="https://cdn.gastroguide.de/{{ addon.image }}" />
                <br />
                <div class="btn-addon">
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="loadingFreeTables || !selectedTime || editErrorMsg !== ''"
                    (click)="nextAddOn(addon, i)"
                  >
                    {{ 'widget.reservation.skip' | translate }}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="loadingFreeTables || !selectedTime || editErrorMsg !== ''"
                    (click)="selectAddOn(addon, i)"
                  >
                    {{ 'widget.reservation.choose' | translate }}
                  </button>
                </div>
                <br />
              </ng-template>
            </div>
          </mat-step>
          <!-- Find Table Ends -->
          <!-- Contact Details Starts -->
          <mat-step [editable]="isEditable" [completed]="false">
            <ng-template matStepLabel>{{ 'widget.reservation.contactData' | translate }}</ng-template>

            <div class="success-msg" *ngIf="settings.isAutomatic">
              {{ 'widget.reservation.freeTablesMsg' | translate }}
            </div>
            <form [formGroup]="guestDetailsFormGroup">
              <!-- Use reCAPTCHA v3 component, which now works automatically -->
              <ng-template [ngIf]="hasCaptcha">
                <app-recaptcha-v3 (tokenGenerated)="onCaptchaTokenReceived($event)"></app-recaptcha-v3>
              </ng-template>
              <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="25px grid">
                <div fxFlex>
                  <div fxLayout="row" fxLayoutGap="15px">
                    <div class="form-group" fxFlex="50">
                      <label for="">{{ 'widget.reservation.userDataForm.name' | translate }}</label>
                      <input class="form-control" placeholder="" type="text" formControlName="name" required />
                    </div>
                    <div class="form-group" fxFlex="50">
                      <label for="">{{ 'widget.reservation.userDataForm.surname' | translate }}</label>
                      <input class="form-control" placeholder="" type="text" formControlName="surname" required />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'widget.reservation.userDataForm.email' | translate }}</label>
                    <input class="form-control" placeholder="" type="email" formControlName="email" required />
                  </div>
                  <div fxLayout="row" fxLayoutGap="15px">
                    <div class="form-group" fxFlex="50">
                      <label for="">{{ 'widget.reservation.userDataForm.phone' | translate }}</label>
                      <ng-select
                        [items]="countries"
                        bindLabel="countryLabel"
                        bindValue="phoneCode"
                        formControlName="countryCode"
                        required
                        #dropdown
                      >
                      </ng-select>
                    </div>
                    <div class="form-group" fxFlex="50">
                      <label for="" style="visibility:hidden">{{
                        'widget.reservation.userDataForm.phone' | translate
                      }}</label>
                      <input class="form-control" type="tel" autocomplete="tel" formControlName="phone" required />
                    </div>
                  </div>

                  <mat-checkbox *ngIf="selectedTicketPrice" formControlName="generateInvoice">
                    {{ 'widget.reservation.generateInvoice' | translate }}
                  </mat-checkbox>
                  <div *ngIf="guestDetailsFormGroup.value.generateInvoice">
                    <div class="form-group">
                      <label for="">{{ 'widget.reservation.userDataForm.company' | translate }}</label>
                      <input class="form-control" placeholder="" type="text" formControlName="company" />
                    </div>
                    <div class="form-group">
                      <label for="">{{ 'widget.reservation.userDataForm.address' | translate }}</label>
                      <input class="form-control" placeholder="" type="text" formControlName="address" />
                    </div>
                    <div fxLayout="row" fxLayoutGap="15px">
                      <div class="form-group" fxFlex="50">
                        <label for="">{{ 'widget.reservation.userDataForm.zipcode' | translate }}</label>
                        <input class="form-control" placeholder="" type="text" formControlName="zipcode" />
                      </div>
                      <div class="form-group" fxFlex="50">
                        <label for="">{{ 'widget.reservation.userDataForm.city' | translate }}</label>
                        <input class="form-control" placeholder="" type="text" formControlName="city" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="">{{ 'widget.reservation.userDataForm.addMsg' | translate }}</label>
                    <textarea class="form-control" placeholder="" rows="4" formControlName="addMsg"></textarea>
                  </div>
                  <mat-checkbox formControlName="informSms" *ngIf="smsSettings && smsSettings.reservationActive">
                    {{ 'widget.reservation.informSms' | translate }}
                  </mat-checkbox>
                  <br />
                  <mat-checkbox formControlName="newsletter" *ngIf="settings.showSubscribeNewsletter">
                    {{
                      'widget.reservation.signupNewsletter'
                        | translate: { clientName: client?.name | specialCharacters }
                    }}
                  </mat-checkbox>

                  <div fxLayout="row" fxLayout.xs="column wrap" fxLayoutGap="15px" id="saveUserData">
                    <mat-checkbox [(ngModel)]="isUserSavingHisData" [ngModelOptions]="{ standalone: true }">
                      {{ 'widget.reservation.saveData' | translate }}
                    </mat-checkbox>
                  </div>
                </div>
                <div fxFlex>
                  <h2 style="margin-bottom: 0px">{{ client?.name | specialCharacters }}</h2>
                  <p>{{ client?.street }}, {{ client?.zipCode }} {{ client?.location }}</p>

                  <ul class="reservation-summary">
                    <li *ngIf="selectedDate">
                      <svg-icon
                        src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/calendar-1.svg"
                        [svgStyle]="{ 'height.px': 14 }"
                      ></svg-icon>
                      <span>{{ selectedDate | date: 'EEE dd. MMM yyyy' }} </span>
                    </li>
                    <li>
                      <svg-icon
                        src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/clock.svg"
                        [svgStyle]="{ 'height.px': 14 }"
                      ></svg-icon>
                      <span>{{ selectedTime }} </span>
                      <span *ngIf="endTime && settings && settings.showStayTime"> &nbsp; - {{ endTime }} </span> &nbsp;
                      Uhr
                    </li>
                    <li>
                      <svg-icon
                        src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/people.svg"
                        [svgStyle]="{ 'height.px': 14 }"
                      ></svg-icon>
                      <span
                        >{{ guestCount }}
                        <ng-container *ngIf="selectedShiftObject?.type === 0">
                          <span *ngIf="guestCount === 1">{{ 'widget.reservation.person' | translate }}</span>
                          <span *ngIf="guestCount > 1">{{ 'widget.reservation.persons' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="selectedShiftObject?.type === 1">
                          <span *ngIf="guestCount === 1">{{
                            'widget.reservation.ticketCountSingular' | translate
                          }}</span>
                          <span *ngIf="guestCount > 1">{{ 'widget.reservation.ticketCountPlural' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="selectedShiftObject?.type === 2">
                          <span *ngIf="guestCount === 1">{{ selectedShiftObject?.tableLabel?.amountLabelSingle }}</span>
                          <span *ngIf="guestCount > 1">{{ selectedShiftObject?.tableLabel?.amountLabelPlural }}</span>
                        </ng-container>
                      </span>
                    </li>
                  </ul>

                  <ng-template [ngIf]="selectedAddOns.length > 0">
                    <p>{{ 'widget.reservation.selectedAddOns' | translate }}</p>
                    <ul class="addon-option" style="padding-left:5%" *ngFor="let selectedAddOn of selectedAddOns">
                      <li>
                        {{ selectedAddOn.title | googleTranslate: userLang | async }} ({{
                          selectedAddOn.price | number: '1.2'
                        }}
                        €)
                      </li>
                    </ul>
                  </ng-template>

                  <ng-template [ngIf]="selectedTicketPrice">
                    <p>
                      {{ 'widget.reservation.selectedTickets' | translate }} :
                      <b>{{ selectedTicketPrice - serviceCharge | number: '1.2' }} € </b>
                    </p>
                    <ng-container *ngIf="selectedShiftObject?.type === 0">
                      <ul
                        class="addon-option"
                        style="margin:0px;padding-left:5%"
                        *ngFor="let data of selectedTicketData"
                      >
                        <span *ngIf="data">
                          <li>
                            {{ data.name | googleTranslate: userLang | async }} ({{ data.price | number: '1.2' }} €)
                          </li>
                        </span>
                      </ul>
                    </ng-container>
                    <ng-container *ngIf="selectedShiftObject?.type === 1 || selectedShiftObject?.type === 2">
                      <div>
                        {{ guestCount }}x {{ selectedTicketData[0].name | googleTranslate: userLang | async }} ({{
                          selectedTicketData[0].price | number: '1.2'
                        }}
                        €)
                      </div>
                    </ng-container>
                    <ul
                      class="addon-option"
                      style="margin:0px;padding-left:5%"
                      *ngFor="let data of selectedTicketAddOn"
                    >
                      <span *ngIf="data">
                        <li>
                          {{ data.name | googleTranslate: userLang | async }} ({{ data.optionAmount | number: '1.2' }}
                          €)
                        </li>
                      </span>
                    </ul>
                    <br />
                  </ng-template>

                  <div>
                    <div>
                      <div>
                        <h4>
                          {{ 'widget.reservation.extraInfos.title' | translate }}
                        </h4>

                        <p
                          *ngIf="!settings.infoText"
                          [innerHTML]="
                            'widget.reservation.extraInfos.message'
                              | translate
                                : {
                                    selectedStaytime: selectedStaytime / 60,
                                    hoursLabel:
                                      selectedStaytime / 60 > 1
                                        ? ('widget.reservation.extraInfos.Hours' | translate)
                                        : ('widget.reservation.extraInfos.Hours' | translate)
                                  }
                          "
                        ></p>

                        <p
                          *ngIf="settings.infoText"
                          [innerHTML]="
                            settings.infoText
                              | infoText
                                : {
                                    aufenthaltsdauer: selectedStaytime / 60
                                  }
                              | googleTranslate: userLang
                              | async
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-danger borderless" *ngIf="processErrorMsg" style="margin: 25px 0 0 0;">
                <b>{{ 'widget.reservation.error' | translate }}</b>
                {{ processErrorMsg | googleTranslate: userLang | async }}
              </div>

              <button
                mat-raised-button
                color="primary"
                class="btn-submit"
                (click)="processReservation()"
                [disabled]="isFormSubmitting || !reservationToken"
              >
                <span *ngIf="selectedTicketPrice; else content">
                  {{ 'widget.reservation.continueToPay' | translate }}
                </span>
                <ng-template #content>
                  <span>{{ 'widget.reservation.submit' | translate }}</span>
                </ng-template>
              </button>
            </form>

            <p
              class="info-p"
              [innerHTML]="
                (settings.termsConditions
                  ? 'widget.reservation.acceptPolicyWithAGB'
                  : 'widget.reservation.acceptPolicy'
                )
                  | translate
                    : {
                        termsUrl: settings.termsConditions
                          ? settings.termsConditions
                          : 'https://www.gastroguide.de/footer/agb',
                        privacyPolicyUrl: 'https://www.gastroguide.de/footer/datenschutzerklaerung',
                        clientName: client?.name | specialCharacters
                      }
              "
            ></p>
          </mat-step>
          <!-- Contact Details Ends -->
          <!-- Done Starts -->
          <mat-step editable="false">
            <ng-template matStepLabel>{{ 'widget.reservation.steps.done' | translate }}</ng-template>

            <div class="success-screen" *ngIf="reservationRes" fxLayout="row">
              <div fxFlex="66" fxFlexOffset="15" fxFlex.xs="100" fxFlexOffset.xs="0">
                <svg-icon
                  src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/check-1.svg"
                  class="success-icon"
                  [svgStyle]="{ 'height.px': 75 }"
                ></svg-icon>
                <h2 style="color: #028e00">{{ 'widget.reservation.success.title' | translate }}</h2>
                <br />
                <ng-template [ngIf]="!reservationRes.payment && reservationRes.status == 'confirmed'">
                  <!-- <p
                    [innerHTML]="
                      'widget.reservation.success.messageConfirmed'
                        | translate
                          : {
                              name: guestDetailsFormGroup.value.name,
                              companion: 'widget.reservation.extraInfos.Accompaniment' | translate,
                              date: selectedDate ? (selectedDate | dfnsFormat: 'dddd, DD. MMMM') : '',
                              time: selectedTime,
                              clientName: client?.name | specialCharacters
                            }
                    "
                  ></p> -->

                  <p
                    [innerHTML]="
                      'widget.reservation.success.messageConfirmed'
                        | translate
                          : {
                              clientName: reservationRes?.client?.name | specialCharacters,
                              name: guestDetailsFormGroup.value.name,
                              companion: 'widget.reservation.extraInfos.Accompaniment' | translate,
                              date: reservationRes?.reservedFor | date: 'EEEE, dd MMMM',
                              time: reservationRes?.reservedFor | date: 'HH:mm'
                            }
                    "
                  ></p>
                  <p *ngIf="reservationRes">#{{ reservationRes.id }}</p>

                  <button
                    class="btn-pay"
                    *ngIf="reservationRes.token"
                    mat-raised-button
                    color="primary"
                    (click)="goToEdit()"
                  >
                    {{ 'widget.reservation.editReservation' | translate }}
                  </button>

                  <button
                    class="btn-pay"
                    *ngIf="reservationRes.shift && reservationRes.shift.menuItem"
                    mat-raised-button
                    color="primary"
                    (click)="goToOrder(reservationRes.shift.menuItem)"
                  >
                    {{ 'widget.reservation.preOrderFood' | translate }}
                  </button>
                </ng-template>

                <ng-template
                  [ngIf]="
                    !reservationRes.payment &&
                    (reservationRes.status == 'pending' || reservationRes.status == 'blocked')
                  "
                >
                  <p
                    [innerHTML]="
                      'widget.reservation.success.messagePending'
                        | translate
                          : {
                              name: guestDetailsFormGroup.value.name,
                              date: reservationRes?.reservedFor | date: 'EEEE, dd MMMM',
                              time: reservationRes?.reservedFor | date: 'HH:mm',
                              clientName: client?.name | specialCharacters
                            }
                    "
                  ></p>

                  <p *ngIf="reservationRes">#{{ reservationRes.id }}</p>

                  <button
                    class="btn-pay"
                    *ngIf="reservationRes.token"
                    mat-raised-button
                    color="primary"
                    (click)="goToEdit()"
                  >
                    {{ 'widget.reservation.editReservation' | translate }}
                  </button>

                  <button
                    class="btn-pay"
                    *ngIf="reservationRes.shift && reservationRes.shift.menuItem"
                    mat-raised-button
                    color="primary"
                    (click)="goToOrder(reservationRes.shift.menuItem)"
                  >
                    {{ 'widget.reservation.preOrderFood' | translate }}
                  </button>
                </ng-template>

                <ng-template [ngIf]="reservationRes.payment">
                  <div
                    [innerHTML]="
                      'widget.reservation.success.paymentPending'
                        | translate
                          : {
                              name: guestDetailsFormGroup.value.name,
                              date: reservationRes?.reservedFor | date: 'EEEE, dd MMMM',
                              time: reservationRes?.reservedFor | date: 'HH:mm',
                              clientName: client?.name | specialCharacters
                            }
                    "
                  ></div>
                  <div id="outer">
                    <div class="inner">
                      <button
                        class="btn-pay"
                        *ngIf="reservationRes.token"
                        mat-raised-button
                        color="primary"
                        (click)="goToEdit()"
                      >
                        {{ 'widget.reservation.editReservation' | translate }}
                      </button>
                    </div>
                    <div class="inner">
                      <button
                        class="btn-pay"
                        *ngIf="reservationRes.shift && reservationRes.shift.menuItem"
                        mat-raised-button
                        color="primary"
                        (click)="goToOrder(reservationRes.shift.menuItem)"
                      >
                        {{ 'widget.reservation.preOrderFood' | translate }}
                      </button>
                    </div>
                    <div class="inner">
                      <button type="submit" class="btn-pay" mat-raised-button color="primary" (click)="goToPay()">
                        <svg-icon
                          src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/pay-1.svg"
                          class="white-svg"
                          [svgStyle]="{ 'height.px': 20, 'margin-right.px': 5, 'margin-top.px': -3 }"
                        >
                        </svg-icon>
                        {{ 'widget.reservation.proceedToPayment' | translate }}
                      </button>
                    </div>
                    <img
                      src="https://s3.eu-central-1.amazonaws.com/gastro.digital/imgs/payment_logos.png"
                      class="payment-logos"
                    />
                  </div>
                </ng-template>
              </div>
            </div>
          </mat-step>
          <!-- Done Ends -->
        </mat-horizontal-stepper>

        <div style="padding: 25px;" *ngIf="showPayment">
          <div class="order-details-box">
            <div class="order-main-info">
              <span>Reservierung #</span>
              <strong style="text-transform: uppercase;">
                {{ reservationRes?.client.name | specialCharacters | slice: 0:3 }}{{ reservationRes?.id }}
              </strong>
            </div>
            <div class="order-sub-info">
              <span>{{ 'widget.reservation.reservedFor' | translate }}</span
              ><strong>{{ reservationRes?.reservedFor | date: 'EEEE, dd.MM.yyyy HH:mm' }}</strong>
            </div>
          </div>

          <hr />

          <div *ngIf="!paymentSuccess">
            <div class="alert alert-status">
              <b
                >Status:
                <span
                  class="status-pill"
                  [class.pending]="reservationRes.status == 'pending' || reservationRes.status == 'blocked'"
                  [class.confirmed]="reservationRes.status === 'confirmed'"
                ></span>
                {{ translateStatus(reservationRes?.status) }}</b
              >
              <p
                *ngIf="reservationRes && reservationRes.status === 'canceled'"
                [innerHtml]="'widget.reservation.payment.info' | translate"
              ></p>
            </div>
            <ng-container *ngIf="reservationRes.status !== 'canceled'">
              <ng-template [ngIf]="!showBuyFreeButton">
                <h3>{{ 'widget.reservation.payment.selectPaymentMethod' | translate }}</h3>
                <mat-radio-group
                  aria-label="Select an option"
                  [(ngModel)]="paymentMethod"
                  (change)="changePaymentMethod($event)"
                >
                  <div class="payment-method" *ngIf="nativePayments.googlePay">
                    <mat-radio-button value="googlepay">
                      <div>
                        <b>{{ 'widget.reservation.payment.googlePay.title' | translate }}</b>
                        <span class="payment-method-description">{{
                          'widget.reservation.payment.googlePay.description' | translate
                        }}</span>
                      </div>
                      <img src="/assets/img/G-Pay.png" style="height:35px" />
                    </mat-radio-button>
                  </div>
                  <div class="payment-method" *ngIf="nativePayments.applePay">
                    <mat-radio-button value="applepay">
                      <div>
                        <b>{{ 'widget.reservation.payment.applePay.title' | translate }}</b>
                        <span class="payment-method-description">{{
                          'widget.reservation.payment.applePay.description' | translate
                        }}</span>
                      </div>
                      <img
                        src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/applepay.png"
                        style="height:35px"
                      />
                    </mat-radio-button>
                  </div>
                  <div class="payment-method">
                    <mat-radio-button value="cc">
                      <div>
                        <b>{{ 'widget.reservation.payment.creditCard.title' | translate }}</b>
                        <span class="payment-method-description">{{
                          'widget.reservation.payment.creditCard.description' | translate
                        }}</span>
                      </div>
                      <div>
                        <img src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/mastercard.png" />
                        <img src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/visa.png" />
                      </div>
                    </mat-radio-button>
                  </div>
                  <div class="payment-method">
                    <mat-radio-button value="paypal">
                      <div>
                        <b>{{ 'widget.reservation.payment.paypal.title' | translate }}</b>
                        <span class="payment-method-description">{{
                          'widget.reservation.payment.paypal.description' | translate
                        }}</span>
                      </div>
                      <img src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/paypal.png" />
                    </mat-radio-button>
                  </div>
                  <div class="payment-method">
                    <mat-radio-button value="klarna">
                      <div>
                        <b>{{ 'widget.reservation.payment.klarna.title' | translate }}</b>
                        <span class="payment-method-description">
                          {{ 'widget.reservation.payment.klarna.description' | translate }}
                        </span>
                      </div>
                      <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" />
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
              </ng-template>
              <section *ngIf="selectedTicketPrice">
                <h4>{{ 'widget.reservation.redeemVoucher' | translate }}</h4>
                <div style="display: flex;align-items: flex-end;">
                  <div class="form-group" fxFlex="30" id="voucher">
                    <input
                      class="form-control"
                      placeholder="{{ 'widget.reservation.voucherCode' | translate }}"
                      type="text"
                      [(ngModel)]="enteredVoucher"
                      [attr.disabled]="voucher && voucher.voucherValue && !voucherError ? '' : null"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      style="height:37px;margin-left: 10%;"
                      color="primary"
                      class="btn mat-raised-button mat-primary"
                      (click)="checkVoucher()"
                      [disabled]="
                        !enteredVoucher ||
                        enteredVoucher.length < 3 ||
                        (voucher && voucher.voucherValue && !voucherError)
                      "
                    >
                      {{ 'widget.reservation.redeem' | translate }}
                    </button>
                  </div>
                </div>

                <div class="alert alert-danger" style="margin-top:10px" *ngIf="voucherError">{{ voucherError }}</div>

                <div
                  *ngIf="voucher && voucher.voucherValue && !voucherError"
                  class="alert alert-success"
                  style="margin-top:10px"
                >
                  <h5>{{ 'widget.reservation.voucherSuccessfullyRedeemed' | translate }}</h5>
                  {{ 'widget.reservation.voucherRedeemMsg1' | translate }} {{ voucher.code }}
                  {{ 'widget.reservation.voucherRedeemMsg2' | translate }}
                  {{ voucher.residualValue | currency: 'EUR' }}.
                  {{ 'widget.reservation.voucherRedeemMsg3' | translate }}
                  {{ voucher.voucherValue | currency: 'EUR' }}
                  {{ 'widget.reservation.voucherRedeemMsg4' | translate }}.
                  <br />
                  <button
                    color="light"
                    style="margin-top: 10px; background-color: white;"
                    class="btn mat-flat-button mat-light"
                    (click)="removeVoucher()"
                  >
                    {{ 'widget.reservation.cancelRedemption' | translate }}
                  </button>
                </div>
              </section>

              <div style="margin-top: 40px">
                <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutGap="25px grid">
                  <div fxFlex>
                    <h5 class="order-section-heading">{{ 'widget.reservation.overview' | translate }}</h5>

                    <ng-template [ngIf]="selectedTicketPrice">
                      <ng-template [ngIf]="selectedTicketData && selectedTicketData.length > 0">
                        <div class="order-summary-row" *ngFor="let data of selectedTicketData">
                          <div class="order-summary-label">
                            <span>
                              {{ data.name | googleTranslate: userLang | async }}
                            </span>
                          </div>
                          <div class="order-summary-value">{{ data.price | number: '1.2' }} €</div>
                        </div>
                      </ng-template>

                      <ng-container *ngFor="let data of selectedTicketAddOn">
                        <div class="order-summary-row" *ngIf="data">
                          <div class="order-summary-label">
                            <span>
                              {{ data.name | googleTranslate: userLang | async }}
                            </span>
                          </div>
                          <div class="order-summary-value">{{ data.optionAmount | number: '1.2' }} €</div>
                        </div>
                      </ng-container>

                      <div class="order-summary-row" *ngIf="serviceCharge && serviceCharge != 0.0">
                        <div class="order-summary-label">
                          <span>
                            {{ 'widget.reservation.processingFee' | translate }}
                          </span>
                        </div>
                        <div class="order-summary-value">{{ serviceCharge | number: '1.2' }} €</div>
                      </div>
                    </ng-template>

                    <div class="order-summary-row" *ngIf="!selectedTicketPrice">
                      <div class="order-summary-label">
                        <!-- <span *ngIf="selectedTicketPrice">
                          {{ 'widget.reservation.paymentTickets' | translate }}
                        </span> -->
                        <span>
                          {{ 'widget.reservation.deposit' | translate }}
                        </span>

                        <strong
                          >{{ reservationRes?.peopleCount }} {{ 'widget.reservation.guestCount' | translate }} á
                          {{ reservationRes?.payment?.price | number: '1.2' }} €</strong
                        >
                      </div>
                      <div class="order-summary-value">{{ reservationRes?.payment?.total | number: '1.2' }} €</div>
                    </div>

                    <div class="order-summary-row" *ngIf="!selectedTicketPrice">
                      <div class="order-summary-label">
                        <span>{{ 'widget.reservation.taxes' | translate }}</span
                        ><strong>19% MwSt.</strong>
                      </div>
                      <div class="order-summary-value">{{ 0 | number: '1.2' }} €</div>
                    </div>

                    <div class="order-summary-row as-total">
                      <div class="order-summary-label">
                        <span>{{ 'widget.reservation.total' | translate }}</span>
                        <span *ngIf="selectedTicketPrice"> {{ 'widget.reservation.includingVAT' | translate }}</span>
                      </div>
                      <div class="order-summary-value" *ngIf="!selectedTicketPrice">
                        {{ reservationRes?.payment?.total | number: '1.2' }} €
                      </div>
                      <div class="order-summary-value" *ngIf="selectedTicketPrice">
                        {{ selectedTicketPrice | number: '1.2' }} €
                      </div>
                    </div>

                    <ng-template
                      [ngIf]="
                        selectedTicketPrice && voucher && voucher.id && finalPriceAfterVoucher !== selectedTicketPrice
                      "
                    >
                      <div class="order-summary-row">
                        <div class="order-summary-label">
                          <span>
                            {{ 'widget.reservation.voucherRedeemedAmount' | translate }}
                          </span>
                        </div>
                        <div class="order-summary-value">- {{ voucher.voucherValue | number: '1.2' }} €</div>
                      </div>
                      <div class="order-summary-row as-total">
                        <div class="order-summary-label">
                          <span>
                            {{ 'widget.reservation.restPaid' | translate }}
                          </span>
                        </div>
                        <div class="order-summary-value">{{ reservationRes?.payment?.total | number: '1.2' }} €</div>
                      </div>
                    </ng-template>
                  </div>
                  <div fxFlex>
                    <h5 class="order-section-heading">{{ 'widget.reservation.pay' | translate }}</h5>

                    <div
                      class="alert alert-warning"
                      *ngIf="!paymentMethod"
                      [innerHtml]="'widget.reservation.payment.noPaymentMethodSelected' | translate"
                    ></div>

                    <div
                      [hidden]="
                        !paymentMethod ||
                        (paymentMethod && paymentMethod !== 'applepay' && paymentMethod !== 'googlepay')
                      "
                    >
                      <div class="alert alert-info borderless" *ngIf="paymentMethod === 'applepay'">
                        {{ 'widget.reservation.payment.applePay.info' | translate }}
                      </div>
                      <div class="alert alert-info borderless" *ngIf="paymentMethod === 'googlepay'">
                        {{ 'widget.reservation.payment.googlePay.info' | translate }}
                      </div>
                      <div class="error-msg">
                        {{ paymentError | googleTranslate: userLang | async }}
                      </div>
                      <div id="payment-button-element" #prButtonEl></div>
                    </div>

                    <div [hidden]="!paymentMethod || (paymentMethod && paymentMethod !== 'cc')">
                      <form novalidate (ngSubmit)="payReservation()" [formGroup]="stripeData" class="cc-form">
                        <div class="form-group">
                          <label for="">{{ 'widget.reservation.payment.ccForm.name' | translate }}</label>
                          <input class="form-control" placeholder="" type="text" formControlName="name" required />
                        </div>
                        <div class="form-group">
                          <label for="">{{ 'widget.reservation.payment.ccForm.number' | translate }}</label>
                          <div id="card-element" #cardNumberEl class="form-control"></div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap="15px grid">
                          <div class="form-group" fxFlex>
                            <label for="">{{ 'widget.reservation.payment.ccForm.expiry' | translate }}</label>
                            <div id="card-expiry-element" #cardExpiryEl class="form-control"></div>
                          </div>
                          <div class="form-group" fxFlex>
                            <label for="">{{ 'widget.reservation.payment.ccForm.cvv' | translate }}</label>
                            <div id="card-cvv-element" #cardCvvEl class="form-control"></div>
                          </div>
                        </div>
                        <div class="error-msg">
                          {{ paymentError | googleTranslate: userLang | async }}
                        </div>
                        <button
                          type="submit"
                          class="btn-submit"
                          [promiseBtn]="payingPromise"
                          mat-raised-button
                          color="primary"
                        >
                          {{ 'widget.reservation.payNow' | translate }}
                        </button>
                      </form>
                    </div>

                    <div [hidden]="!paymentMethod || (paymentMethod && paymentMethod !== 'paypal')">
                      <div class="alert alert-info borderless">
                        {{ 'widget.reservation.payment.paypal.info' | translate }}
                      </div>
                      <div *ngIf="processingPaypal" class="loading-payment">
                        <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                        {{ 'widget.reservation.payment.paypal.processing' | translate }}
                      </div>
                      <ngx-paypal [config]="payPalConfig" [hidden]="processingPaypal"></ngx-paypal>
                    </div>

                    <div [hidden]="!paymentMethod || (paymentMethod && paymentMethod !== 'klarna')">
                      <div class="alert alert-info borderless">
                        {{ 'widget.reservation.bankTransferPayment' | translate }}
                        <br />
                        {{ 'widget.reservation.successMail' | translate }}
                      </div>
                      <button
                        type="submit"
                        class="btn-submit"
                        (click)="payReservation()"
                        [promiseBtn]="payingPromise"
                        mat-raised-button
                        color="primary"
                      >
                        {{ 'widget.reservation.payKlarna' | translate }}
                      </button>
                    </div>

                    <div [hidden]="!paymentMethod || (paymentMethod && paymentMethod !== 'free')">
                      <button
                        type="submit"
                        class="btn-submit"
                        (click)="payReservation()"
                        [promiseBtn]="payingPromise"
                        mat-raised-button
                        color="primary"
                      >
                        {{ 'widget.reservation.buyForFree' | translate }}
                      </button>
                    </div>

                    <div id="payment-request-button">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>

                    <div
                      *ngIf="selectedTicketPrice"
                      style="display: flex; justify-content: center; align-items: center;"
                    >
                      <button
                        style="height:37px;"
                        color="primary"
                        class="btn mat-raised-button mat-primary"
                        (click)="sendPaymentLinkViaEmail()"
                      >
                        <i class="fas fa-envelope" aria-hidden="true"></i>
                        {{ 'widget.reservation.sendPaymentLinkViaEmail' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="success-screen" *ngIf="paymentSuccess" fxLayout="row">
            <div fxFlex="66" fxFlexOffset="15" fxFlex.xs="100" fxFlexOffset.xs="0">
              <svg-icon
                src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/check-1.svg"
                class="success-icon"
                [svgStyle]="{ 'height.px': 75 }"
              ></svg-icon>
              <h2 *ngIf="selectedTicketPrice" style="color: #028e00">
                {{ 'widget.reservation.success.title' | translate }}
              </h2>
              <h2 *ngIf !="selectedTicketPrice" style="color: #028e00">
                {{ 'widget.reservation.payment.success.title' | translate }}
              </h2>
              <br />
              <p
                [innerHTML]="
                  (!selectedTicketPrice
                    ? 'widget.reservation.payment.success.message'
                    : 'widget.reservation.success.ticketPurchased'
                  )
                    | translate
                      : {
                          clientName: reservationRes?.client?.name | specialCharacters,
                          name: reservationRes?.guestData.name,
                          companion: 'widget.reservation.extraInfos.Accompaniment' | translate,
                          date: reservationRes?.reservedFor | date: 'EEEE, dd MMMM',
                          time: reservationRes?.reservedFor | date: 'HH:mm'
                        }
                "
              ></p>
              <p *ngIf="reservationRes">#{{ reservationRes.id }}</p>

              <!-- Download Tickets -->
              <!-- <div [ngStyle]="{ display: allTickets.length > 0 ? 'block' : 'none' }" style="margin-left:23%;" class="form-group">
                <table class="table">
                  <thead>
                    <td style="text-align: center;">
                      <b> {{ 'widget.reservation.ticketName' | translate }}</b>
                    </td>
                    <td style="text-align: center;">
                      <button (click)="downloadTicketAll(allTickets)" class="btn btn-primary">
                        {{ 'widget.reservation.downloadAll' | translate }}
                      </button>
                    </td>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ticket of allTickets">
                      <td style="text-align: center;">
                        {{ ticket.filename }}
                      </td>
                      <td style="text-align: center;">
                        <i style="cursor: pointer;" (click)="downloadTicket(ticket)" class="fa fa-download"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <ng-template [ngIf]="selectedTicketPrice">
                <div *ngIf="allTickets.length > 0">
                  <button class="btn-pay" mat-raised-button color="primary" (click)="downloadTicketAll(allTickets)">
                    {{ 'widget.reservation.downloadAll' | translate }}
                  </button>
                  {{ 'widget.reservation.additionalInfoForDownload' | translate }}
                </div>
                <div class="payment-container" *ngIf="!allTickets.length > 0">
                  <!-- <div class="payment-message">{{ 'widget.reservation.paymentInProgress' | translate }}</div>
                  <div class="loading-dots">
                    <div class="dot dot1"></div>
                    <div class="dot dot2"></div>
                    <div class="dot dot3"></div>
                    <div class="dot dot4"></div>
                    <div class="dot dot5"></div>
                  </div> -->
                  <div *ngIf="!hasTimedOut; else timeOutMsg">
                    <div class="payment-message">{{ 'widget.reservation.pleaseWaitGeneratingTicket' | translate }}</div>
                    <div class="timer-container">
                      <div class="timer"><span id="minutes">01</span>:<span id="seconds">00</span></div>
                    </div>
                  </div>
                  <ng-template #timeOutMsg>
                    <div class="payment-message">{{ 'widget.reservation.issueInProcessingPayment' | translate }}</div>
                  </ng-template>
                </div>
              </ng-template>

              <button
                class="btn-pay"
                *ngIf="reservationRes.token"
                mat-raised-button
                color="primary"
                (click)="goToEdit()"
              >
                {{ 'widget.reservation.editReservation' | translate }}
              </button>

              <button
                class="btn-pay"
                *ngIf="reservationRes.shift && reservationRes.shift.menuItem"
                mat-raised-button
                color="primary"
                (click)="goToOrder(reservationRes.shift.menuItem)"
              >
                {{ 'widget.reservation.preOrderFood' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="footer-info" *ngIf="!iFrameMode">
        {{ client?.name | specialCharacters }} {{ 'widget.reservation.useOnlineRes' | translate }}
        <a target="_blank" href="https://www.gastro.digital/reservierungssystem/">gastro.digital</a>
      </p>

      <div *ngIf="debugInfo && isAdmin && !iFrameMode" class="debug-box">
        <b>{{ 'widget.reservation.debug' | translate }}</b>
        <code *ngFor="let table of debugInfo; let i = index">
          {{ i + 1 }}. #{{ table.name }}: ({{ table.seats }} {{ 'widget.reservation.places' | translate }},
          {{ table.minOccupancy }} {{ 'widget.reservation.minOccupancy' | translate }},
          {{ 'widget.reservation.combination' | translate }}:
          {{
            table.isCombined
              ? ('widget.reservation.extraInfos.Yes' | translate)
              : ('widget.reservation.extraInfos.No' | translate)
          }},
          <ng-template [ngIf]="table.room"
            >{{ 'widget.reservation.room' | translate }}:
            {{ table.room.name | googleTranslate: userLang | async }})</ng-template
          >
        </code>
      </div>
    </div>
  </ng-template>
</div>
